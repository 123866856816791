


<style>
/* .serviceCards{
  display: flex;

} */
.serviceCard {
  padding: 16px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  color: black !important;
}
.serviceCard .infoPart {
  order: 1;
  width: 75%;
}

.serviceCard .infoPart p {
  font-weight: 400;
  color: black;
}

/* 
.serviceCard svg {
  min-width: 150px;
  padding: 1.5rem;
} */
.serviceCard .v-card__actions {
  width: 100%;
  order: 3;
}
.serviceCard .v-card__title {
  font-size: 32px;
}
.serviceCard .v-card__text {
  font-size: 24px;
  font-weight: 300 !important;
  font-family: "system-ui";
  line-height: 120%;
}
.serviceCard svg {
  order: 2;
  max-width: 25%;
  height: 100%;
  margin-top: auto;
  margin-bottom: auto;
  width: 25%;
  padding: 0.5rem 1rem !important;
  filter: grayscale(0.5);
}
@media only screen and (max-width: 600px) {
  .serviceCard {
    flex-direction: column;
  }

  .serviceCard .infoPart {
    order: 2;
  }
  .serviceCard svg {
    order: 1;
    margin: auto;
  }
}
/* .mainCard::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #ffffff45;
  border-radius: 16px !important;
} */

/* 


.logoCard.aos-animate svg g#letters path {
  stroke-dashoffset: 0;
}
 */
@keyframes dash {
  75% {
    stroke-dashoffset: 0;
  }
  100% {
    stroke-dashoffset: 0;
    fill-opacity: 1;
  }
}

.slidingDown {
  animation: slidingDown 4s ease infinite;
  opacity: 0;
}
@keyframes slidingDown {
  0% {
    transform: translateY(0%);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }

  90% {
    opacity: 0;
  }

  100% {
    transform: translateY(50px);
    opacity: 0;
  }
}
.slideRight {
  animation: slideRight 4s ease infinite;
}
@keyframes slideRight {
  0% {
    transform: translateX(0%);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }

  90% {
    opacity: 0;
  }

  100% {
    transform: translateX(50px);
    opacity: 0;
  }
}
.page {
  position: relative;
  min-height: calc(100vh - 64px);
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0%;
  min-width: 100%;
  padding: 0%;
}

.bgSlideShow {
  position: absolute !important;
  top: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
}
.topSlideShow {
  height: 200px;
  position: relative;
  width: 100%;
}
.overlay {
  position: absolute !important;
  top: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
}
.bgSlideShow * {
  height: 100% !important;
}
.bgSlideShow .v-image__image {
  filter: blur(8px) grayscale(0.7) contrast(0.9);
}

/* 
.drawIt {
  fill-opacity: 0;
  stroke: black;
  stroke-dasharray: 10000;
  stroke-dashoffset: 10000;
  animation: dash all 4s ease 1s, fill-opacity 3s ease 1.5s;
}
.aos-animate .drawIt {
  fill-opacity: 1;
  stroke-dashoffset: 0;
} */
</style>
<template>
  <div class="home">
    <v-dialog v-model="thankYouDialog" hide-overlay max-width="500">
      <v-card class="pa-2">
        <v-list-item three-line>
          <v-list-item-content>
            <v-list-item-title class="headline mb-1">
              {{ $t("thankYou").toUpperCase() }}
            </v-list-item-title>
            <v-list-item-subtitle>
              {{ $t("thankYouForContacting") }}</v-list-item-subtitle
            >
          </v-list-item-content>

          <v-list-item-avatar tile size="75"
            ><v-icon color="green" x-large
              >mdi-check</v-icon
            ></v-list-item-avatar
          >
        </v-list-item>
      </v-card>
    </v-dialog>

    <v-container class="page align-start white">
      <div class="overlay glass2"></div>
      <v-img
        v-if="$vuetify.breakpoint.mdAndUp"
        style="position: absolute;top: 0px;height: 100%;width: 100%;left: 0;object-fit: cover;filter: blur(12px) grayscale(.1);"
        :src="require('@/assets/bgs/environmental_policy.jpg')"
      ></v-img>
      <!--   <div class="topSlideShow" style="height:100%!important">
        <v-img
          height="200"
          :src="require('@/assets/bgs/environmental_policy.jpg')"
        ></v-img>
        <div class="d-flex overlay glass2">
          <v-card-title class="ma-auto bordered-title">
            <span>{{ $t("EnvironmentalPolicy") }}</span>
          </v-card-title>
        </div>
      </div> -->
      <v-row class="w-100" no-gutters>
        <v-col cols="12" md="7" class="pa-0 ma-auto">
          <v-card shaped elevation="4" class="pa-2">
            <v-card-title
              class="ma-auto bordered-title"
              style="border:0px!important"
            >
              <span>{{ $t("EnvironmentalPolicy") }}</span>
            </v-card-title>
            <v-divider />
            <v-card-text>
              {{ $t("EnvironmentalPolicyIntro") }}
            </v-card-text>
            <v-list dense>
              <v-subheader> {{ $t("ExamplesThat") }}</v-subheader>
              <v-list-item-group color="primary">
                <v-list-item>
                  <v-list-item-icon>
                    <v-icon>mdi-circle</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    {{ $t("ExampleOne") }}
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-icon>
                    <v-icon>mdi-circle</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    {{ $t("ExampleTwo") }}
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-icon>
                    <v-icon>mdi-circle</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    {{ $t("ExampleThree") }}
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-icon>
                    <v-icon>mdi-circle</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    {{ $t("ExampleFour") }}
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
// @ is an alias to /src
import axios from "axios";
export default {
  name: "EnvironmentalPolicy",
  data: () => ({
    services: [
      {
        title: "movingCleaning",
        content: "movingCleaningContent",
        learnMore: false,
        icon: "moving_cleaning"
      },
      {
        title: "homeCleaning",
        content: "homeCleaningContent",
        learnMore: false,
        icon: "house_cleaning"
      },
      {
        title: "officeCleaning",
        content: "officeCleaningContent",
        learnMore: false,
        icon: "office"
      }
    ],
    sliderSettings: {
      arrows: false,
      dots: false
    },
    selectedService: "",
    thankYouDialog: false,
    bookingDialog: false,
    contactingIsLoading: false,
    contactForm: false,
    formEmail: "",
    formName: "",
    formMessage: "",
    rules: {
      required: value => !!value || "Required.",
      counter: value => value.length <= 20 || "Max 20 characters",
      email: value => {
        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(value) || "Invalid e-mail.";
      }
    }
  }),
  methods: {
    async validate(token) {
      this.$refs.form.validate();
      console.log(this.contactForm);
      this.contactingIsLoading = true;

      try {
        let subresult = await axios.post("https://sudtek.se/mail/contact", {
          "g-token": token,
          email: this.formEmail,
          name: this.formName,
          address: this.formAddress,
          phone: this.formPhone,
          space: this.formSpace
        });
        console.log("subresult", subresult);
        if (subresult.data.duplicate) {
          console.log("Duplicate", subresult.data.sub);
          if (subresult.data.sub.subscription_status == 1) {
            this.dialogState = 3;
          }
          return;
        }
        if (subresult.data.type == "error") {
          if (subresult.data.text == "tooSoon") this.dialogState = 4;
          return;
        }
        this.emailSub = "";
        this.dialogState = 2;
      } catch (error) {
        console.log("error in ajax", error);
        this.dialog = false;
      }
      setTimeout(() => {
        this.thankYouDialog = true;
        this.formEmail = "";
        this.formMessage = "";
        this.formName = "";
        this.contactForm = false;
        this.contactingIsLoading = false;
        this.thankYouDialog = false;
        this.bookingDialog = false;
      }, 5000);
    }
  }
};
</script>
